.subtitle {
  text-align: left;
  padding-top: 30px;
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 16px;
}

.extra {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-color: var(--grey-form-backround);
  padding: 10px 20px;
}

.extra > label {
  margin: 20px 30px 0 0;
}
