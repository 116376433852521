.dragger {
  display: flex;
  align-items: center;
}

.draggerDisabled {
  opacity: 0.3 !important;
}

.uploadArrow {
  position: absolute;
  top: 70px;
}

.bold {
  margin: 0 5px;
  font-weight: 700;
  color: var(--primary-red);
}
