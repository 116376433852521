.notFound {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.code {
  font-size: 120px;
  color: var(--primary-blue);
}

.oops {
  margin-right: 10px;
  color: var(--primary-red);
  font-size: 60px;
}

.title {
  width: 80%;
  margin: auto;
  color: var(--primary-blue);
  font-size: 60px;
  text-align: center;
}

.button {
  justify-content: center;
  height: 5vh;
  width: 20vw;
  margin-top: 40px;
  color: var(--white);
  font-weight: 500;
  border-radius: 30px;
}

.back {
  margin-left: 15px;
}
