.login {
  width: 35vw;
  padding: 50px 85px 16px 65px;
}

.password__field {
  margin-top: 45px;
  margin-bottom: 5px;
}

.password__forgot {
  text-decoration: none;
  color: var(--primary-red);
  font-weight: 400;
}

.login__button {
  margin-top: 50px;
  padding: 25px 90px;
  color: var(--white);
  font-weight: 600;
}

.input {
  height: 45px;
  border: var(--grey) 1px solid;
  border-radius: 4px;
}

.flex {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.return_link {
  margin-top: 30px;
  padding: 10px 30%;
  margin-left: auto;
  text-decoration: none;
  color: var(--primary-red);
  font-weight: 600;
}

.confirmBtn {
  margin-top: 30px;
  padding: 25px 40px;
  text-align: left;
}
