.form_container {
  width: 68vw;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 50px;
  padding-top: 30px;
}

.form_content {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.title_container {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

a.csv_btn {
  display: flex;
  align-items: center;
  padding: 25px!important;
  border-radius: 30px;
  font-weight: 600;
  background-color: var(--primary-blue);
  color: var(--white);
  border: none;
}

a.csv_btn:hover {
  background-color: var(--grey);
  color: var(--white);
}
a.csv_btn:active, a.csv_btn:focus {
  background-color: var(--primary-blue);
  color: var(--white);
  border: none;
}

.form {
  text-align: center;
  margin-bottom: 40px;
}

.or_title {
  text-align: center;
  margin: 30px 0;
}

.btn_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proceed {
  display: flex;
  border-radius: 30px;
  margin: auto;
  width: 300px;
  height: 45px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.link {
  border-color: var(--primary-red);
  background: var(--primary-red);
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  transition: .3s;
}

.link:hover {
  color: #fff;
  background-color: #d46f68;
}
p {
  margin: 0;
}

.paragraph {
  display: flex;
  margin: auto;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-weight: 700;
  background-color: var(--grey-form-backround);
}

.information {
  display: flex;
  margin: 55px 0 100px 0;
  padding: 30px;
  border-radius: 10px;
  font-weight: 700;
  background-color: var(--grey-form-backround);
}

.icon {
  margin-right: 60px;
  width: 20px;
  height: 20px;
}

.details_btn {
  display: flex;
  align-items: center;
  margin: 35px 0 0 90px;
  padding: 20px 65px;
  border-radius: 20px;
  font-weight: 600;
}

