.options {
  position: absolute;
  z-index: 200;
  top: 25px;
  right: 0;
  width: 200px;
  height: auto;
  padding: 15px;
  box-shadow: 1px 12px 23px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: var(--white);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.optionsBtn {
  width: 45px;
  font-weight: 900;
  border-radius: 30px;
  border: none;
}

.optionLink {
  padding: 0;
  margin: 0;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--primary-blue);
  font-size: 16px;
  font-weight: 600;
  transition: .3s;
  text-align: start;
}

.optionLink:hover {
  color: #d46f68;
}

.optionsBtn:hover {
  color: var(--primary-red);
  background-color: var(--grey-options-background);
}

.modal {
  text-align: start;
}

a {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-blue);
}
