.menu_container {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
  padding: 5px 0;
  list-style: none;
}

.menu_item {
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
}

.link_button {
  background-color: transparent;
  display: inline-block;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  width: 100%;
  text-align: start;
  cursor: pointer;
  transition: .3s;
}

.link_button:hover {
  color: var(--primary-red);
}