.ant-drawer-header {
  padding: 30px 46px;
  border-bottom: none;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-close {
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 24px;
  color: #000;
}

.ant-drawer-title {
  font-weight: 800;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #1B2936;
}