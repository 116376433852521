.head_info {
  background: var(--grey-form-backround);
}

.back_link_container {
  padding: 25px 60px 0 60px;
}

.height_container {
  padding: 25px 60px;
}

.title_container {
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: 0!important;
}

.status {
  margin: 0;
  margin-left: 20px;
}

.p_top_none {
  padding-top: 0;
}

.text_margin_bottom {
  margin-bottom: 15px;
}

.back {
  display: flex;
}

.total_cost_container {
  font-weight: 700;
  font-size: 20px;
}

.order_type {
  display: flex;
  align-items: center;
}

.total_cost {
  font-weight: 800;
  font-size: 30px;
  color: var(--primary-red);
  margin-left: 10px;
}

.select_input {
  width: 130px;
  margin-left: 10px;
}

.paragraph {
  font-weight: 600;
  font-size: 16px;
  text-align: start;
  line-height: 19px;
}
.support_btn_contaier {
  display: flex;
  justify-content: flex-end;
}

.buttons_column_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  width: 50%;
  float: right;
}

.buttons_column_container > button {
  width: 100%;
}

.buttons_container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 29px;
}

.confirm_btn, a.confirm_btn {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.col_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.shipment_description, 
.shipment_description_row {
  display: flex;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
}

.shipment_description_row {
  align-items: center;
}

.edit_btn {
  height: 0;
}
.edit_btn > span {
  position: absolute;
  margin-left: 10px;
  width: 16px;
  height: 16px;
}

.address {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: 800;
  margin-left: 10px;
}

.error {
  font-weight: 600;
  color: #ff4d4f
}

.options_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  margin-right: 20px;
}

.options_bolt_text {
  font-weight: 900;
  margin-right: 5px;
  margin-left: 5px;
}

.gdo_label {
  background-color: var(--primary-red);
  color: #fff;
  padding: 1px 3px;
  border-radius: 5px;
}