.date_input, .ant-picker {
  width: 233px;
  height: 32px;
  border: 1px solid #d9d9d9;
}

.date_input .ant-picker-suffix {
  margin-left: 10px;
  border-left: 1px solid var(--gray-radio-button);
}

.date_input .ant-picker-suffix > span {
  margin-left: 10px;
}

.form_checkbox .ant-checkbox-inner,
.form_checkbox:hover .ant-checkbox::after {
  border-radius: 5px;
}
