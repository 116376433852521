.container {
    padding: 5% 10%;
    text-align: left;
}

.color_red {
    color: var(--primary-red)
}

.text_left {
    text-align: left;
}

.margin_bottom {
    margin-bottom: 30px;
}

.margin_top {
    margin-top: 20px;
}

.main_top {
    margin-top: 40px;
}