.charts_container {
    padding: 30px 5% 0;
}

.info_container {
    text-align: center;
    padding-bottom: 50px;
}
.grey_container {
    height: 100%;
    background: var(--grey-options-background);
    padding: 60px 35px;
}

.gap_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.select {
    height: 30px;
    width: 100%;
}

.range_picker {
    border: 1px solid #d9d9d9;
}

.color_container {
    padding: 50px 0;
    background: var(--primary-red);
}

.title_style {
    font-family: 'Raleway', serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--white)
}

.count_style {
    margin-top: 10px;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    color: var(--white)
}

.legend_content {
    text-align: start;
}

.custom_tooltip {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
}

.tooltip_title {
    font-size: 16px;
    margin: 5px 0;
}

.tooltip_text {
    font-size: 14px;
    margin: 5px 0;
    font-weight: 400;
}