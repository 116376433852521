.title {
  margin-bottom: 50px;
}

.select_form_item > div,
.form_item {
  border-radius: 4px!important;
}

.date_input {
  width: 100%;
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.submit_btn {
  padding: 18px 45px;
}
