.google_result_list {
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #ccc;
    z-index:  100;
    position: absolute;
    width: 100%;
    max-height: 150px;
}
.google_item {
    padding: 3px 7px;
}

.google_item:hover {
    background-color: #fafafa;
    cursor: pointer;
}

.gdo_select_name,
.gdo_select_address {
    margin: 0;
    padding: 0;
    text-align: start;
}

.gdo_select_name {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
}

.gdo_select_address {
    font-size: 12px;
    color: #c5c5c5;
}
