.modal_head_container {
  padding: 0 30px 30px;
}

.modal_title {
  margin: 0;
}

.shipping_status_id {
  text-transform: lowercase;
}

.bold_text, .light_text {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  padding-left: 5px;
  text-align: start;
}

.light_text {
  font-weight: inherit;
  font-size: 14px;
}

.date_picker_container {
  display: flex;
  align-items: center;
}

.date_picker {
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  padding: 0 14px;
  height: 25px;
  margin-left: 5px;
}

.address_container {
  margin-top: 20px;
}

.address_column {
  display: flex;
  align-items: baseline;
}

.gap_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.primary_color {
  color: var(--primary-red);
  margin-right: 5px;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.min_width_160 {
  min-width: 160px;
}

.cost_text {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.price {
  font-weight: 800;
  font-size: 30px;
  color: var(--primary-red);
  text-align: start;
  padding-left: 5px;
  position: relative;
}

.edit_price {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.align_right {
  text-align: right;
}

.modal_input_container {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.footer_modal {
  height: 50px;
  background: var(--white);
}

.add_option_btn {
  padding-left: 5%;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
}

.footer_styles {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 45px;
  background-color: var(--white);
  padding-bottom: 20px;
}
.update_btn {
  padding: 15px;
}