.notificationItemContainer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 18px 15px 18px 50px;
  position: relative;
  cursor: pointer;
}

.notificationItemContainer p {
  text-align: start;
}

.timeDate {
  font-weight: 700;
  font-size: 12px;
  color: #333333;
}

.text {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.activeNotification::before {
  content: '';
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: var(--primary-red);
  position: absolute;
  left: 21px;
  top: 50%;
  transform: translate(0, -50%);
}

.activeNotification p {
  font-weight: 700;
}