.card_container {
  width: 275px;
}

.header {
  background-color: var(--primary-blue);
  display: flex;
  justify-content: space-between;
  color: var(--white);
  min-height: 56px;
  position: relative;
  z-index: 1;
}

.title {
  font-weight: 900;
  font-size: 20px;
  color: var(--white);
  margin: 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  padding: 0 20px;
}

.padding_left {
  padding-left: 17px;
}

.options_container {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 32px;
  width: 32px;
}

.options_btn {
  background-color: transparent;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
}

.options_btn:hover {
  cursor: pointer;
}

.dots,
.dots::after,
.dots::before {
  position: absolute;
  background-color: #fff;
  height: 5px;
  width: 5px;
  left: 50%;
  border-radius: 50%;
  transition: .3s;
}

.dots {
  top: 50%;
  transform: translate(-50%, -50%);
}

.dots::after,
.dots::before {
  content: '';
  transform: translateX(-50%);
}

.dots::before {
  top: -10px;
}

.dots::after {
  bottom: -10px;
}

.options_btn:hover .dots,
.options_btn:hover .dots::after,
.options_btn:hover .dots::before {
  background-color: var(--primary-red);
}

.options {
  position: absolute;
  z-index: 200;
  width: 200px;
  height: auto;
  padding: 15px;
  box-shadow: 1px 12px 23px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: var(--white);
  text-align: left;
}

.option_item {
  color: var(--primary-black);
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.option_item:hover {
  cursor: pointer;
  color: var(--primary-red);
}

.name {
  font-weight: 400;
  font-size: 14px;
}

.number_of {
  background-color: var(--primary-red);
  display: flex;
  align-items: center;
  padding: 15px 8px;
  font-weight: 900;
  font-size: 18px;
}

.body {
  padding: 20px 15px;
  background-color: var(--grey-options-background);
  min-height: 300px;
  max-height: 450px;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.body::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.body::-webkit-scrollbar-track {
  border-radius: 100px;
}

.body::-webkit-scrollbar-thumb {
  background-color: rgba(27, 41, 54, 0.2);
  border-radius: 100px;
}