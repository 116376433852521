.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-weight: 600;
  padding: 20px 30px;
}

.link {
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  color: var(--white);
  display: block;
}
