@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
}

* {
  box-sizing: border-box;
}

.main-title-30px {
  font-weight: 800;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
}

.semi-bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.extra-bolt {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.extra-bolt-corsivo {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
}

.main-title-25px {
  font-weight: 900;
  font-size: 25px;
  line-height: 29px;
}

.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.title-20px {
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
}

.bolt-14px {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

:root {
  --white: #fff;
  --black: #000;
  --grey-backround: #e5e5e5;
  --grey-form-backround: #F8F8F8;
  --grey-options-background: #F0F0F0;
  --grey: #aaa;
  --primary-red: #C64641;
  --primary-blue: #1B2936;
  --primary-black: #333333;
  --gray-radio-button: #CCCCCC;
  --gray--alternative: #F5F5F5;
  --gray--tab: #7D7D7D;
  --gray-400: #9CA3AF;
  --gray-200: #E5E7EB;
  --blue-status-bgc: #E1F1F6;
  --blue-status-c: #004A70;
  --black-status-bgc: #DCDCDC;
  --green-status-bgc: #DFF0D0;
  --green-status-c: #438F3C;
  --pink-status-bgc: #F9D6D5;
  --pink-status-c: #A42139;
  --peach-status-bgc: #FCDDB1;
  --peach-status-c: #926626;
  --bg-blue: #394D60;
}

@primary-color: #C64641;@btn-primary-color: #C64641;@btn-default-color: #1B2936;@label-required-color: #1B2936;