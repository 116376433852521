.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  background-color: var(--primary-blue);
  color: var(--white);
}

.banner > h1 {
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--white);
}

.underline {
  width: 150px;
  border-top: 3px var(--primary-red) solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
