.red {
  color: var(--primary-red);
}

.bold {
  font-weight: 700;
}

.extraBold {
  font-weight: 900;
}

.info {
  padding-left: 5px;
}

.delete_icon {
  transform: scale(1.2);
  width: 0;
  overflow: visible;
  z-index: 2;
  margin-left: 10px;
  position: absolute;
  left: 25px;
}

.gdo_label {
  background-color: var(--primary-red);
  color: #fff;
  padding: 1px 3px;
  border-radius: 5px;
}