.details_container {
  width: 70%;
  padding-top: 70px;
}

.first_step_container {
  padding-bottom: 60px;
}

.left {
  margin-left: 35%;
}

.title {
  font-weight: 800;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
}

.price_container {
  border-radius: 10px 0 0 10px;
  position: absolute;
  right: -27px;
  top: 60px;
  background: var(--primary-red);
  width: 24%;
  height: 107px;
}

.price_container_triangle {
  position: absolute;
  width: 29px;
  height: 27px;
  right: -27px;
  top: 167px;
  border-style: solid;
  border-width: 29px 27px 0 0;
  border-color: var(--primary-red) transparent transparent transparent;
  opacity: 0.7;
}

.price {
  font-weight: 900;
  font-size: 55px;
  line-height: 65px;
  text-align: center;
  color: var(--white);
  margin-bottom: 0;
}

.price_iva {
  font-weight: 700;
  font-size: 25px;
  margin: 0;
  text-align: center;
  color: var(--white);
}

.params_container {
  padding-left: 35%;
  padding-top: 40px;
}

.params_title {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: var(--primary-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.params_detail {
  color: var(--primary-blue);
  font-weight: 900;
}

.params_margin {
  margin-left: 36px;
}

.extra_services_container {
  position: absolute;
  z-index: 1;
  left: -85px;
  background: var(--grey-form-backround);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 38%;
  max-height: 300px;
  min-height: 200px;
  height: auto;
  padding: 35px 5px 35px 40px;
}

.extra_title {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.extra_title > img {
  padding-left: 5px;
}

.extra_list_price, .extra_list_price:hover, .extra_list_price:focus {
  color: var(--primary-red);
}

.delivery_text {
  color: var(--primary-red);
  font-weight: 700;
}

.two_step_form {
  margin-left: 10%;
  margin-right: 10%;
}

.radio_group {
  margin: 25px 0;
}

.segregate_div {
  margin-bottom: 40px;
}

.two_step_container {
  background: var(--gray--alternative);
  padding: 35px 0 55px 0;
  border-top: 1px dashed rgb(27, 41, 54, 0.2);
  border-bottom: 1px dashed rgb(27, 41, 54, 0.2);
}

/* BILLING INFO */

.billing {
  padding: 40px 130px 90px 130px;
}

.billing_title {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.vatNumber {
  width: 330px;
  margin-bottom: 15px;
}

.span {
  padding-right: 10px;
  border-right: #aaa 1px solid;
}

.street {
  width: 350px;
}

.addressesSame {
  margin-top: 20px;
}

.payment {
  width: 50%;
}

.send {
  width: 250px;
  height: 55px;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 15px;
}

.text_first_step {
  margin-left: 35%;
  margin-right: 15%;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primary-black)
}

.text_two_step {
  margin-right: 15%;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primary-black)
}


.no_data_style {
  position: absolute;
  top: 50px;
  right: 30%;
  left: 30%;
  text-align: center;
}


.house_number {
  width: 70px;
}

.date_top {
  margin-top: 25px;
}

.non_stop_date_input {
  width: 100%;
}