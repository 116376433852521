.scarico_details_modal_container {
  top: 0;
  position: relative;
  min-height: 50vh;
}

.badge {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-red);
  color: #fff;
  border-radius: 0 10px 10px 0;
  padding: 15px 25px;
  width: 230px;
  position: absolute;
  left: -25px;
  top: 20px;
  font-size: 16px;
}

.badge::before {
  content: '';
  position: absolute;
  border-top: 12.5px solid #a84e4b;
  border-right: 12.5px solid #a84e4b;
  border-bottom: 12.5px solid transparent;
  border-left: 12.5px solid transparent;
  bottom: -25px;
  left: 0;
}

.header {
  margin-bottom: 45px;
}

.title {
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  color: #fff;
}

.badge_text {
  font-weight: 600;
  line-height: 23px;
}

.deliveryType {
  text-transform: uppercase;
  font-weight: 900;
}

.description {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 600;
  line-height: 19px;
  font-size: 16px;
}

.address {
  display: flex;
  flex-direction: column;
  font-weight: 800;
  margin-top: 10px;
}

.details_option {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.details_option sub {
  font-size: 12px;
}

.modal_btn {
  height: 45px;
  padding: 15px 35px;
}

.modal_footer {
  display: flex;
  justify-content: space-between;
}

.d_flex_end {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.success_badge {
  padding: 15px 20px;
  color: #fff;
  display: flex;
  background-color: var(--green-status-c);
  border-radius: 20px;
  font-size: 16px;
}

.success_badge_content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.status {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
}

.badge_date {
  font-weight: 900;
  font-size: 12px;
}

.error_message {
  font-size: 14px;
  color: #ff4d4f;
  line-height: 16px;
  margin-top: 5px;
  font-weight: 400;
  text-align: start;
  margin-bottom: 10px;
}