@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap');

.header {
  font-family: 'Roboto', sans-serif;
}

.info {
  height: 35px;
  margin: auto;
  justify-content: space-around;
  background-color: var(--grey-backround);
}

.info,
.contacts,
.phone,
.email,
.subinfo,
.account,
.nav_menu {
  display: flex;
  align-items: center;
}

.phone > img,
.email > img,
.account > img {
  height: 15px;
  margin-right: 5px;
}

.subinfo {
  text-transform: uppercase;
  font-size: 12px;
}

.tracking,
.account {
  font-weight: 700;
  font-size: 12px;
}

.phone,
.account,
.tracking,
.language {
  padding: 0 15px;
}

.phone {
  margin-right: 15px;
}

.logo {
  width: 180px;
  position: relative;
  right: 40%;
}

.account {
  display: flex;
  align-items: baseline;
  border-right: 2px var(--black) solid;
  font-size: 12px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
}

.menu {
  margin: 0 0 0 10px;
  list-style: none;
}

.menu_item {
  margin: 0 8px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3em;
  line-height: 4.6em;
}

.nav_menu > li > a,
.phone_number > a,
.email_address > a {
  text-decoration: none;
  color: var(--black);
}
