.action_select {
  width: 155px!important;
}

.action_select .ant-select-selector {
  background: var(--primary-red)!important;
  color: var(--white);
  border-radius: 100px!important;
  border: none !important;
  height: 40px!important;
  position: relative;
}

.action_select .ant-select-selector::after {
  width: 48px!important;
  background: #B42B25;
  height: 100%;
  visibility: visible;
  border-radius: 0px 100px 100px 0px;
  position: absolute;
  right: 0;
  top: 0;
}


.action_select .ant-select-selection-item,
.action_select .ant-select-selection-placeholder {
  padding-top: 5px!important;
  padding-right: 35px!important;
  font-weight: 700;
  color: #fff;
}

.action_select .ant-select-arrow {
  position: absolute;
  right: 19px;
  color: #fff;
}

.truck_select {
  margin-top: 35px;
}

.truck_select .ant-select-selector {
  border-radius: 10px!important;
  position: relative;
}

.truck_select .ant-select-selector::after {
  width: 28px!important;
  background: #B42B25;
  height: 100%;
  visibility: visible;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1.07);
}

.truck_select .ant-select-arrow {
  color: #fff;
}