.private_header {
  width: 100%;
  height: 65px;
  background: var(--white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

.logo_col {
  text-align: center;
}

.logo_col img {
  width: 120px;
  height: 30px;
}

.menu_col {
  display: flex;
  align-items: center;
  height: 100%;
}

.menu {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 40px;
}
.menu_item {
  font-weight: 800;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.menu_item::before,
.menu_item_active::before {
  bottom: 0;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  transition: .3s cubic-bezier(.645,.045,.355,1);
  border-bottom: 2px solid transparent;
}

.menu_item:hover, .menu_item_active {
  color: var(--primary-red);
}

.menu_item:hover::before,
.menu_item_active::before {
  border-bottom: 2px solid var(--primary-red);
}

.icons_container {
  display: flex;
  margin-right: 30px;
}
.icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.badge_container {
  margin-right: 10px;
  position: relative;
}

.badge::before {
  content: '';
  position: absolute;
  background-color: var(--primary-red);
  height: 11px;
  width: 11px;
  border-radius: 50%;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 0px 2px rgb(198 70 65 / 50%), 0px 0px 0px 4px rgb(198 70 65 / 20%);
}

.icon:hover {
  color: var(--primary-red)
}


@media screen and (max-width: 1350px) {
  .private_header {
    min-width: 100vw;
  }
}
