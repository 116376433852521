.title {
  font-size: 18px;
  text-transform: uppercase;
  text-align: start;
}

.bold {
  font-weight: 800;
}

.progressBar {
  margin: 50px 0 120px 0;
}

.stage {
  padding: 20px 0;
  border-bottom: var(--gray-radio-button) 2px solid;
}

.date_time {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 12px;
}