.new_estimate_container {
  padding: 0 10%;
}

.title_container {
  padding-top: 50px;
}
.new_estimate_form {
  margin-top: 40px;
}

.title {
  margin-top: 20px;
}

.bottom {
  margin-bottom: 10px;
}

.buttons_container {
  display: flex;
  padding: 50px 10%;
}

.proceedBtn {
  margin-right: 20px;
  padding: 15px 50px;
}

.cancelBtn {
  padding: 15px 40px;
}

.contractorsSelect {
  margin-bottom: 25px;
}

.type_title {
  margin: 20px 0 30px 0;

}

.checkbox_top {
  margin-bottom: 10px;
}

.radio_gap {
  margin-right: 150px;
}

.contractor_select {
  width: 250px;
  margin-top: 10px;
}

.note_style {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
}

.input_container {
  display: flex;
  align-items: flex-end;
}

.options_gap_container {
  gap: 48px;
  align-items: flex-end;
}
