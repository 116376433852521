.tracking {
  display: flex;
  justify-content: center;
  padding: 65px 305px 95px 305px;
  width: 80vw;
  font-weight: 600;
}

.button {
  margin: auto;
  margin-top: 50px;
  padding: 25px 90px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.label {
  text-align: center;
  margin-bottom: 24px;
  color: var(--primary-blue);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

}

.input {
  width: 430px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
}

.trackingProgress {
  width: 80vw;
  padding: 50px 120px 235px 120px;
}

.not_tracking_text {
  margin-top: 50px;
  color: #1B2936;
  font-weight: 600;
  font-size: 16px;
}
