.customForm {
  position: relative;
  z-index: 1;
  top: -30px;
  margin: auto;
  box-shadow: 1px 12px 23px 3px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: var(--grey-form-backround);
  color: var(--primary-blue);
  font-weight: 600;
}

.defaultBtn {
  display: flex;
  background-color: var(--primary-blue);
  color: var(--white);
  border-color: var(--primary-blue);
  transition: .3s;
}

.defaultBtn:hover, .defaultBtn:focus {
  background-color: var(--grey);
  color: var(--white);
  border-color: var(--grey);
}

.suffix {
  padding-left: 10px;
  border-left: 1px solid var(--gray-radio-button);
}
.non_stop_background {
  background: rgba(225, 95, 0, 0.18);
}
.non_stop_background .ant-table-cell-row-hover {
  background: rgba(225, 95, 0, 0.18) !important;
}

.row_approved {
  background-color: #e0f6d6;
}
.row_approved .ant-table-cell-row-hover {
  background-color: #e0f6d6 !important;
}

.street_number_input {
  width: 65px;
}
