.monitoring_container {
    position: absolute;
    width: 100%;
    background: var(--grey-form-backround);
    z-index: 1;
}

.header_container {
    margin: 35px 0 35px 60px;
}

.monitoring_title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 42px;
    color: var(--black);
}

.csv_button {
    width: 171px;
    height: 38px;
    justify-content: center;
}

.filter_container {
    display: flex;
}
.search_input {
    border-radius: 4px 0 0 4px;
    width: 389px;
    height: 30px;
    padding-right: 9px;
}
.searchBtn {
    color: var(--gray--tab);
    width: 156px;
    height: 34px;
    border: 1px solid var(--grey);
    margin-right: 8px;
    padding: 9px;
}

.resetBtn {
    color: var(--gray--tab);
    width: 83px;
    height: 34px;
    border: 1px solid var(--grey);
    padding: 9px;
}

.searchBtn:hover,
.searchBtn:active,
.searchBtn:focus,
.resetBtn:hover,
.resetBtn:active,
.resetBtn:focus {
    color: var(--gray--tab);
}


.labelSearch {
    text-align: left;
}

.operator_select {
    width: 220px;
}

.date_input {
    width: 158px;
    height: 30px;
}
