.status_select {
  font-family: 'Raleway', sans-serif;
  width: 169px !important;
}

.status_select .ant-select-selector {
  border-radius: 100px !important;
  border: none !important;
}

.status_select .ant-select-selection-item {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: start;
}

.status_select .ant-select-selection-item >p {
  font-size: 14px;
}

.blue_status .ant-select-selector {
  background-color: var(--blue-status-bgc) !important;
  color: var(--blue-status-c);
}

.peach_status .ant-select-selector {
  background: var(--peach-status-bgc) !important;
  color: var(--peach-status-c);
}

.green_status .ant-select-selector {
  background: var(--green-status-bgc) !important;
  color: var(--green-status-c);
}

.pink_status .ant-select-selector {
  background: var(--pink-status-bgc) !important;
  color: var(--pink-status-c);
}

.black_status .ant-select-selector {
  background: var(--black-status-bgc) !important;
  color: var(--primary-black);
}
