.pricelist_container {
  position: absolute;
  width: 100%;
  background: var(--grey-form-backround);
}

.height_container {
  justify-content: space-around;
  width: 95vw;
  margin: 25px 30px 0 40px;
}

.resetBtn {
  color: var(--gray--tab);
  width: 83px;
  height: 34px;
  border: 1px solid var(--grey);
  padding: 9px;
}

.newQuoteBtn {
  width: 171px;
  height: 38px;
  justify-content: center;
}

.priceNumber {
  font-weight: 900;
  font-size: 25px;
}

.membership {
  color: var(--primary-red);
  font-weight: 700;
  margin-right: 10px;
}

.associate {
  padding: 0 20px;
  margin-right: 80px;
}

.actions {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.justify {
  width: 95vw;
  justify-content: space-between;
}

.delete {
  width: 45px;
  height: 40px;
  padding: 12px 16px;
}

.counter {
  margin-left: 10px;
}

.percantage {
  gap: 0 !important;
  margin-top: 35px;
}

.percantage form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioButton {
  margin: 0 5px;
  border-radius: 50%;
  height: 30px;
}

.percentsAction {
  font-size: 30px;
}

.back {
  color: var(--primary-red);
}

p {
  margin-bottom: 10px;
}

.mainActions {
  margin-left: 50px;
}

.pricelistNumber {
  margin: 0 5px;
}

.percent {
  color: var(--white);
  border-radius: 0 10px 10px 0;
}

.percentInput {
  max-width: 100px;
}

.formItem {
  margin-bottom: 0;
}

.percentSelect {
  margin-top: 3px;
  height: 36px;
  max-width: 50px;
  /*border: 1px solid #C64641;*/
  border-right: none !important;
}
.percentSelect > div {
  height: 36px;
  border-right: none !important;
  /*border: none !important;*/
}
