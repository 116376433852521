.progress_bar_container .ant-slider-rail {
  height: 7px;
  background-color: #F0F0F0!important;
  border-radius: 50px;
}

.progress_bar_container .ant-slider-step {
  height: 7px;
}

.progress_bar_container .ant-slider-dot {
  height: 37px;
  width: 37px;
  background-color: #F0F0F0;
  border: none!important;
  top: 50%;
  transform: translate(-50%, -50%)!important;
}

.progress_bar_container .ant-slider-dot-active {
  background-color: var(--primary-red);
}

.progress_bar_container .ant-slider-mark-text {
  margin-top: 25px;
  font-weight: 700;
  font-size: 14px;
  color: #333333;
  opacity: 0.7;
  text-transform: uppercase;
  max-width: 115px;
}

.progress_bar_container .ant-slider-mark-text-active {
  color: var(--primary-red);
  opacity: 1;
}

.progress_bar_container .ant-slider-disabled .ant-slider-track {
  background-color: var(--primary-red)!important;
  height: 7px;
  border-radius: 50px;
}