.table_container {
  padding-left: 28.5%;
}

.table_container .ant-table-thead > tr > th {
  color: var(--primary-blue) !important;
  border-bottom: 1px solid rgba(27, 41, 54, 0.2) !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.table_container .ant-table-tbody {
  background-color: var(--grey-form-backround);
  color: var(--primary-black);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.table_container .ant-table-tbody > tr:not(:last-child) > td {
  border-bottom: 1px solid rgba(27, 41, 54, 0.2) !important;
}

.list_container {
  height: auto;
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list_container .ant-checkbox {
  margin-right: 22px;
}

.list_container::-webkit-scrollbar {
  width: 4px;
}

.list_container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.list_container::-webkit-scrollbar-thumb {
  background-color: var(--primary-blue);
}

.list_container .ant-list-item {
  justify-content: left;
}
