.new_shipment_container {
    padding: 0 10%;
}

.title_container {
    padding-top: 50px;
}

.title {
    margin-top: 20px;
}

.new_shipment_form {
    margin-top: 40px;
}

.radio_group {
    margin: 30px 0 50px 0;
}

.details_part {
    margin-top: 25px;
}

.checkbox_center {
    text-align: center;
    margin-top: 30px;
}

.buttons_container {
    display: flex;
    padding: 50px 10%;
}

.proceedBtn {
    margin-right: 20px;
    padding: 15px 50px;
}

.cancelBtn {
    padding: 15px 40px;
}

.loader_container {
    border-top: 1px dashed rgb(27, 41, 54, 0.2);
    width: 100%;
    padding: 40px 10%;
}

.top_loader {
    margin-top: 10%
}

.type_title {
    margin: 20px 0 30px 0;

}

.checkbox_top {
    margin-bottom: 10px;
}

.radio_gap {
    margin-right: 150px;
}

.contractor_select {
    width: 250px;
    margin-top: 10px;
}

.note_style {
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
}

.input_container {
    display: flex;
    align-items: flex-end;
}

.options_gap_container {
    gap: 48px;
    align-items: flex-end;
}
  
