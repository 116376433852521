a.ant-btn {
  padding: 0;
}

.ant-picker {
  height: 45px;
  border: 1px solid var(--grey);
}

.ant-upload {
  color: var(--primary-red);
}

.ant-show-help-leave {
  display: none;
}

.ant-list-split .ant-list-item {
  border-bottom: none;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-tag {
  min-width: 140px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 12px;
}
.ant-tag-has-color {
  color: var(--primary-black);
}
.ant-table-thead > tr > th {
  font-weight: 700;
  font-size: 14px;
  border-bottom: 1px solid rgba(27, 41, 54, 0.3);
  background: var(--grey-form-backround);
}

.ant-table-tbody > tr:not(:last-child) > td {
  border-bottom: 1px solid rgba(27, 41, 54, 0.3);
}
.ant-table-thead {
  font-weight: 700;
}

.ant-table-column-sorters {
  display: block;
}

.ant-table-column-sorter {
  color: var(--black);
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
}

.ant-modal-content {
  border-radius: 0px 0px 20px 20px;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active  {
  color: var(--white)
}

.quantity_height .ant-input-number-input:placeholder-shown {
  height: 15px;
}

.pallets_table .ant-empty-normal {
  display: none;
}


.ant-input-number-group-addon {
  padding: 0 5px;
}

.ant-select-item-option-content {
  white-space: wrap!important;
  white-space: inherit!important;
}
