.title {
  text-align: center;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}

.text {
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
}

.btn_container {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 30px;
}

.btn_styles {
  padding: 20px;
}