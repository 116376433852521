.invoices_head_container {
  padding: 35px 20px;
  background: var(--grey-form-backround);
}

.invoices_title {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
}

.date_picker_container {
  display: flex;
  gap: 15px;
  margin-top: 25px;
}

.date_picker, .select_user {
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  padding: 0 14px;
  height: 32px;
}

.select_user {
  width: 200px;
  padding: 0;
}

.select_user > div {
  border: none!important;
  height: 100%!important;
}

.btn_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.invoice_btn {
  padding: 18px 45px;
}

.d_flex, .d_flex_column {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.d_flex {
  margin-top: 10px;
}

.d_flex_column {
  flex-direction: column;
}

.bold {
  font-weight: bold;
  margin-left: 5px;
}