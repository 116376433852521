.status {
  width: auto;
  min-width: 120px;
  height: 25px;
  border-radius: 20px;
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 600;
  margin: auto;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status > .ant-select-selection-search {
  background-color: var(--blue-status-bgc);
}

.blueStatus {
  background-color: var(--blue-status-bgc);
  color: var(--blue-status-c);
}

.blackStatus {
  background-color: var(--black-status-bgc);
  color: var(--black);
}

.greenStatus {
  background-color: var(--green-status-bgc);
  color: var(--green-status-c);
}

.peachStatus {
  background-color: var(--peach-status-bgc);
  color: var(--peach-status-c);
}

.pinkStatus {
  background-color: var(--pink-status-bgc);
  color: var(--pink-status-c);
}
