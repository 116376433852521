.side_menu_container {
  height: 100%;
  min-height: 100vh;
  background: var(--grey-form-backround);
  overflow-x: hidden;
  padding-top: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px
}

.side_menu_tab {
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  user-select: none;
  line-height: 40px;
  padding-right: 25px;
}

.side_menu_tab span {
  border-radius: 0 20px 20px 0;
  padding-left: 35px;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.side_menu_tab_active span {
  background: var(--primary-red);
  color: var(--white);
}

.side_menu_tab_active:hover{
  color: var(--white);
}
