.transports_container {
    position: absolute;
    width: 100%;
    background: var(--grey-form-backround);
}

.margin_container {
    margin: 35px 0 35px 60px;
}

.newTransportBtn {
    width: 171px;
    height: 38px;
    justify-content: center;
}

.filter_container {
    display: flex;
}

.searchBtn {
    color: var(--gray--tab);
    width: 156px;
    height: 34px;
    border: 1px solid var(--grey);
    margin-right: 8px;
    padding: 9px;
}

.resetBtn {
    color: var(--gray--tab);
    width: 83px;
    height: 34px;
    border: 1px solid var(--grey);
    padding: 9px;
}

.searchBtn:hover,
.searchBtn:active,
.searchBtn:focus,
.resetBtn:hover,
.resetBtn:active,
.resetBtn:focus {
    color: var(--gray--tab);
}

.bold {
    font-weight: 900;
}

.min_bold {
    font-weight: 700;
}

.filter_input {
    width: 400px;
    margin-right: 20px;
}

.transports_modal {
    padding: 20px 50px 50px 65px;
}

.form_modal {
    margin-top: 35px;
}

.max_width {
    width: 100%;
}

.add_transport_button {
    padding: 20px 50px;
}
.calendar_height {
    height: 30px;
}

.advancedSearch {
    margin-top: 20px;
    display: flex;
    justify-content: start;
}

.select_filter {
    width: 200px;
}
.left {
    text-align: left;
}

.hide_modal_close_icon {
    display: none;
}

