.finance_container {
  padding-bottom: 50px;
}

.finance_btn_container {
  display: flex;
  align-items: center;
}

.finance_table_btn,
.delete_btn,
.disapprove_btn {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  user-select: none;
}

.delete_btn,
.disapprove_btn {
  border-radius: 50%;
  position: relative;
}

.disapprove_btn {
  background-color: #ff4d4f;
}

.delete_btn {
  background-color: #000;
}

.delete_btn::before,
.disapprove_btn::before {
  content: '+';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}

.add_extra_finance {
  padding-left: 5%;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
}
