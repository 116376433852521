.login {
    width: 35vw;
    padding: 50px 85px 16px 65px;
}

.password__field {
    margin-top: 45px;
    margin-bottom: 5px;
}

.password__forgot {
    padding: 25px;
    text-decoration: none;
    color: var(--primary-red);
    font-weight: 600;
}

.login__button {
    margin-top: 10px;
    padding: 25px 90px;
    color: var(--white);
    font-weight: 600;
}

.input {
    height: 45px;
    border: var(--grey) 1px solid;
    border-radius: 4px;
}

.description_text {
    padding-top: 5px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-black)
}

.flex {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}