.card {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 15px 10px 20px;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: column;
  user-select: none;
  cursor: grab;
  position: relative;
  opacity: 1;
  transition: .3s;
}

.card:hover {
  background: #F0F0F0;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 0px 0px 1px rgb(0 0 0 / 20%);
}

.isNonStop {
  background: #FAE2D1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: .3s;
}

.card_opacity_hidden {
  opacity: 0;
}

.card_info {
  display: flex;
  justify-content: space-between;  
}

.bold_text {
  font-weight: 600;
  margin: 0;
}

.number_of {
  font-weight: 900;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.number_of::before {
  content: url(../../../assests/images/penIcon.png);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
}

.shipping_id {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
}

.thin_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
  color: #333333bd;
}

.hover_color {
  transition: .3s;
}
.card:hover .hover_color{
  color: var(--primary-red);
}

.card:hover .number_of {
  visibility: hidden;
}

.card:hover .number_of::before{
  visibility: visible;
}
