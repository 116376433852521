.form_container {
  width: 98vw;
  /*height: 100vh;*/
  display: flex;
  margin: auto;
  padding-top: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title_container {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 40px;
}

a.csv_btn {
  display: flex;
  align-items: center;
  padding: 25px!important;
  border-radius: 30px;
  font-weight: 600;
  background-color: var(--primary-blue);
  color: var(--white);
  border: none;
}

a.csv_btn:hover {
  background-color: var(--grey);
  color: var(--white);
}
a.csv_btn:active, a.csv_btn:focus {
  background-color: var(--primary-blue);
  color: var(--white);
  border: none;
}

.form {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.label {
  font-weight: 700;
  margin: 30px 0 5px 0;
}

.selectWrapper > div > div > div {
  flex-direction: row;
  display: flex;
}

.proceed {
  display: flex;
  margin: auto;
  margin-top: 45px;
  width: 300px;
  height: 45px;
  align-items: center;
  justify-content: center;
}

.resetBtn {
  color: var(--gray--tab);
  width: 110px;
  height: 34px;
  border: 1px solid var(--grey);
  padding: 9px;
}

.resetBtn:hover,
.resetBtn:active,
.resetBtn:focus {
  color: var(--gray--tab);
}

.select {
  width: 400px;
  margin: 0 0 0 20px;
}
