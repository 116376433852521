.operators_list_container {
    width: 100%;
    background: var(--grey-form-backround);
    padding: 40px 0 40px 100px;
}

.operators {
    text-align: left;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
}

.operators_count {
    font-size: 18px;
    line-height: 21px;
    padding-left: 5px;
}

.modal_title {
    text-align: center;
}

.modal_form {
    margin-top: 35px;
}

.role_radio_select {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
}

.modalBtn {
    padding: 20px 50px;
}

.auto_generation_button {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--primary-red);
}

.newUserBtn {
    width: 171px;
    height: 38px;
    justify-content: center;
}
