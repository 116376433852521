.shipmentOne {
  width: 75vw;
  height: auto;
  padding: 45px 85px 45px 65px;
}

.fromToShipment {
  display: flex;
  justify-content: space-between;
}

.fromToItem {
  width: 25vw;
}

.palleteDetails {
  width: 60vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
}

.input {
  height: 40px;
}

.select {
  width: 5vw;
}

.quantity {
  background-color: var(--white);
  border-radius: 2px;
  border: 1px solid var(--grey);
}

.detailsItem {
  width: 8vw;
}

.upload {
  font-weight: 700;
  padding: 0;
  margin-bottom: 40px;
}

.shipmentBtn {
  width: 250px;
  height: 55px;
  justify-content: center;
  margin-top: 40px;
}

.hide {
  visibility: hidden;
  display: none;
}

.visible {
  visibility: visible;
}

.deleteBtn {
 margin: 30px 0;
}

.date_container {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.form_item_date > div {
  width: 260px;
}

.form_item_date {
  color: red;
}

.date_width {
  width: 220px;
}

.error_value {
  position: static;
  padding-top: 5px;
  padding-bottom: 5px;
}

.error_style {
  color: #ff4d4f;
  font-size: 14px;
}