.profile_container {
    white-space:pre;
    padding: 70px 0 40px 40%;
}

.name_top {
    margin-top: 30px;
    margin-bottom: 10px;
}
.status {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 40px;
}
.input {
    width: 434px;
}
.operator_profile_form {
    margin-bottom: 50px;
}

.password_form_container {
    padding: 40px 40%;
    border-top: 1px dashed rgb(27, 41, 54, 0.2);
    border-bottom: 1px dashed rgb(27, 41, 54, 0.2);
    background: var(--grey-form-backround)
}

.confirmBtn {
    padding: 20px 40px;
    text-align: left;
}

.left {
    margin-left: 45%;
}

.notification_form {
    padding: 35px 40%;
}

.radio {
    margin-top: 30px;
}

.radio_select {
    max-width: 120px;
    margin-left: 30px;
}

.user_title {
    margin-top: 20px;
    margin-bottom: 10px;
}

.user_left {
    text-align: left;
}

.user_text {
    font-weight: 400
}
.bolt_text {
    font-weight: 700
}
.primary {
    color: var(--primary-red)
}
.cancel_form {
    border-top: 1px dashed rgb(27, 41, 54, 0.2);
    padding: 65px 0 40px 40%;
}
.error_message {
    color: #ff4d4f;
}

.password__forgot {
    padding: 10px 50%;
    margin-left: auto;
    text-decoration: none;
    color: var(--primary-red);
    font-weight: 600;
}

.blue {
    color: var(--primary-blue)
}

.primary_dark {
    color: var(--primary-black)
}

.text_style {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.bottom {
    margin-bottom: 30px;
}

.flex {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.blue {
    color: var(--primary-blue)
}

.primary_dark {
    color: var(--primary-black)
}

.text_style {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.bottom {
    margin-bottom: 30px;
}

.super_admin_container {
    white-space:pre;
    padding: 20px 0 40px 40%;
}

.super_admin_form {
    margin-top: 30px;
}