.link {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-blue);
  display: inline-block;
}

.button_text {
  color: var(--primary-blue);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 0;
  display: flex;
  align-items: center;
}

.button_img {
  margin-right: 10px;
  height: 29px;
}
