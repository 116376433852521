.filter_container {
    display: flex;
    margin-top: 30px;
}

.filter_input {
    width: 400px;
    margin-right: 20px;
}

.searchBtn {
    color: var(--gray--tab);
    width: 156px;
    height: 34px;
    border: 1px solid var(--grey);
    margin-right: 8px;
    padding: 9px;
}

.resetBtn {
    color: var(--gray--tab);
    width: 83px;
    height: 34px;
    border: 1px solid var(--grey);
    padding: 9px;
}

.searchBtn:hover,
.searchBtn:active,
.searchBtn:focus,
.resetBtn:hover,
.resetBtn:active,
.resetBtn:focus {
    color: var(--gray--tab);
}

.input_number {
    width: 250px !important;
}
