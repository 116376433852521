.input_group_styles {
  display: flex!important;
}

.input_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  max-width: 140px;
}

.input_styles {
  border-radius: 6px;
}

.half_input_styles {
  border-top-left-radius: 6px!important;
  border-bottom-left-radius: 6px!important;
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1B2936;
  margin-bottom: 10px;
}

.error_message {
  font-size: 14px;
  color: #ff4d4f;
  line-height: 16px;
  margin-top: 5px;
  font-weight: 400;
  text-align: start;
}