.primary {
    color: var(--primary-red)
}

.vl_line {
    border-left: 2px solid var(--primary-red);
    height: auto;
    margin: 0 10px;
}

.text_container {
    display: flex;
}
.text_filling {
    text-align: left;
}