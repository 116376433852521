.shipments_container {
  position: absolute;
  width: 100%;
  background: var(--grey-form-backround);
}

.filter_container,
.height_container {
  margin: 35px 60px;
}

.height_container {
  justify-content: space-between;
}

.filter_container {
  display: flex;
}

.searchBtn {
  color: var(--gray--tab);
  width: 156px;
  height: 34px;
  border: 1px solid var(--grey);
  margin-right: 8px;
  padding: 9px;
}

.resetBtn {
  color: var(--gray--tab);
  width: 83px;
  height: 34px;
  border: 1px solid var(--grey);
  padding: 9px;
}

.btn:focus, .btn:hover {
  color: var(--gray--tab);
}

.btn_container {
  display: flex;
  gap: 10px;
}

.new_order_btn {
  padding: 20px 15px;
}

.newQuoteBtn {
  justify-content: center;
}

.options {
  position: absolute;
  z-index: 200;
  top: 50px;
  right: 60px;
  width: 200px;
  height: 210px;
  padding: 15px;
  box-shadow: 1px 12px 23px 3px rgba(0, 0, 0, 0.3);
}

.optionsBtn {
  width: 45px;
  font-weight: 900;
  border-radius: 30px;
  border: none;
}

.optionsBtn:hover {
  color: var(--primary-red);
  background-color: var(--grey-form-backround);
}

.semiBold {
  font-weight: 700;
}

.red {
  font-weight: 700;
  color: var(--primary-red);
}

.selectTitle {
  padding: 0;
  font-weight: 600;
}

.filterSelect {
  width: 24vw;
  margin-right: 30px;
}

.labelSearch {
  text-align: left;
}