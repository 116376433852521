@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

.shipment_container {
  text-align: center;
  width: 60%;
  padding: 70px 15px;
}

.shipment_title {
  margin-top: 30px;
  text-transform: uppercase;
  color: var(--primary-blue);
}

.shipment_description {
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-black);
}

.shipment_email {
  font-weight: 800;
}

.shipment_info {
  margin-top: 30px;
  color: var(--primary-black);
}

.shipment_button {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  color: var(--white);
}

.tracking_number_container {
  padding-top: 20px;
}

.number_container {
  margin-left: auto;
  margin-right: auto;
  width: 398px;
  height: 57px;
  background: rgba(204, 69, 64, 0.26);
  border-radius: 10px;
  padding: 5px 0;
}

.number_text {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 42px;
  color: var(--primary-red);
}
