.loaderContainer {
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100px;
  height: 100px;
  border: 4px solid var(--primary-red);
  border-radius: 50%;
  border-right-color: transparent;
  animation-name: spinning;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

p {
  text-align: center;
  margin-top: 50px;
  font-size: 1.2em;
  font-weight: 600;
}
