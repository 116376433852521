.shipping_approved_container {
  padding: 35px 20px;
  background: var(--grey-form-backround);
}

.filter_container {
  display: flex;
  width: 100%;
}

.searchBtn {
  color: var(--gray--tab);
  width: 156px;
  height: 34px;
  border: 1px solid var(--grey);
  margin-right: 8px;
  padding: 9px;
}

.resetBtn {
  color: var(--gray--tab);
  width: 83px;
  height: 34px;
  border: 1px solid var(--grey);
  padding: 9px;
}

.newShipmentBtn {
  width: 171px;
  height: 38px;
  justify-content: center;
}

.options {
  position: absolute;
  z-index: 200;
  top: 50px;
  right: 60px;
  width: 200px;
  height: auto;
  padding: 15px;
  box-shadow: 1px 12px 23px 3px rgba(0, 0, 0, 0.3);
}

.optionsBtn {
  width: 45px;
  font-weight: 900;
  border-radius: 30px;
  border: none;
}

.optionsBtn:hover {
  color: var(--primary-red);
  background-color: var(--grey-form-backround);
}

.options a {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.primary_color {
  color: var(--primary-red);
  margin-right: 5px;
}

.table_icon {
  padding-left: 5px;
}

.to_be_approved_title {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 42px;
}

.detail_checkbox_title {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
}
.modal_background {
  padding: 0 38px 60px;
}

.detail_modal_container {
  height: calc(100vh - 20px);
  overflow-y: auto;
  overflow-x: hidden;
}
.detail_top_container {
  padding-top: 26px;
}

.address_column {
  display: flex;
  align-items: baseline;
  text-align: start;
}


.gap_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 5px;
  position: relative;
}

.on_edit_container {
  display: flex;
  align-items: flex-end;
}

.on_edit_address_btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.on_edit_btn {
  height: 20px;
}


.driver {
  margin-right: 5px;
}
.cost_text {
  margin-right: 100px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: end;
  color: var(--black);
}

.price_text {
  margin-left: 10px;
  font-weight: 800;
  font-size: 30px;
  line-height: 35px;
  color: var(--primary-red);
}

.detail_button_container {
  display: flex;
  margin-top: 80px;
}

.detail_button {
  padding: 15px 10px;
  margin-right: 30px;
}

.options_container {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 10px;
}

.options_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  margin-right: 15px;
}

.options_bolt_text {
  font-weight: 900;
  margin-right: 5px;
  margin-left: 5px;
}

.footer_modal {
  height: 50px;
  background: var(--white);
}

.addPalletsBtn {
  padding-left: 5%;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
}

.pallet_form {
  padding-left: 5%;
}

.assign_price_list_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}

.assign_price_list_input {
  width: 195px;
  margin-right: 10px;
}

.assign_price_list_button {
  padding: 15px 20px;
}

.date_margin {
  margin-top: 15px;
  margin-bottom: 5px;
}

.pen_image {
  display: inline-block !important;
  cursor: pointer;
  margin-left: 7px;
}

.approved_modal_right_container {
  margin-top: 45px;
}

.advancedSearch {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  width: 95vw;
}

.advancedSearch > div {
  margin-right: 20px;
}

.resetBtn {
  color: var(--gray--tab);
  width: 83px;
  height: 34px;
  border: 1px solid var(--grey);
  padding: 9px;
}

.searchBtn:hover,
.searchBtn:active,
.searchBtn:focus,
.resetBtn:hover,
.resetBtn:active,
.resetBtn:focus {
  color: var(--gray--tab);
}

.status {
  margin-left: 10px;
}

.paragraph {
  text-align: start;
}

.icon {
  margin: 0 5px;
}

.extraservices {
  padding: 50px;
}

.align {
  text-align: start;
}

.select_width {
  width: 350px;
}

.pallex_style {
  min-width: 75px;
  height: 23px;
  border-radius: 100px;
  color: var(--white)
}

.red {
  background: var(--primary-red);
}

.blue {
  background: #1B76CA;
}

.green {
  background: #24A047;
}

.modal_main_title {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
}

.shipping_status_id {
  text-transform: lowercase;
}

.pallex_error_image_table {
  margin-top: 10px;
}

.pallex_error_image_modal {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-top: 5px;
}

.pallex_error {
  font-size: 12px;
  text-transform: capitalize;
  text-align: start;
  line-height: 17px;
  font-weight: 600;
  color: var(--primary-red);
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}

.pallex_error_text_container {
  text-align: start;
}

.pallex_error_btn {
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.pallex_error_btn:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: var(--primary-red);
  bottom: -3px;
}

.status_container {
  display: block;
  position: relative;
  line-height: normal;
  width: 170px;
  margin-top: 10px !important;
  margin-left: 60px !important;
}

.status_select {
  width: 400px;
}

.non_stop {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #CB0000;
}

.pallex_status_container {
  position: relative;
  line-height: normal;
  margin: 0 !important;
}

.filter_form_container {
  width: 1000px;
}

.left {
  width: 145px !important;
  text-align: left !important;
  padding: 5px !important;
  height: auto !important;
}

.status_margin {
  margin-left: 10px;
}

.left_text {
  text-align: left;
  padding: 0;
}

.price_list_top {
  margin-top: 30px
}

.inline {
  display: flex;
}

.tracking_input {
  width: 100%;
}

.labelSearch {
  text-align: left;
}

.cell_styles {
  padding: 3px!important;
}

.col_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.gdo_label {
  background-color: var(--primary-red);
  color: #fff;
  padding: 1px 3px;
  border-radius: 5px;
}

@media screen and (max-width: 1350px) {
  .max_width {
    width: 100vw;
  }
}
