
.extra_name {
  display: flex;
  flex-direction: column;
}

.not_available {
  color: var(--primary-red);
  font-size: 12px;
  font-weight: bold;
}

.table_form_style {
  margin-top: 20px !important;
}

.margin_left {
  margin-left: 10px;
}
