.operators_modal {
    position: absolute;
    top: 0;
    right: 30%;
    border-radius: 0 0 20px 20px;
}

.operators_modal .ant-modal-close {
    right: -50%;
    top: 15px;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: var(--white);
}
.operators_modal .ant-modal-close-icon {
    color: var(--primary-blue);
}



