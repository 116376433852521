.modal_pallex_error {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 30px;
}

.btn_container {
  display: flex;
  justify-content: space-around;
}

.btn {
  height: 45px;
  width: 160px;
  white-space: normal;
  line-height: 15px;
}