select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  color: var(--primary-black);
}

select::-ms-expand {
  display: none;
}

.select {
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px solid var(--gray--tab);
  border-radius: 0.25em;
  /*padding: 0.25em 0.5em;*/
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: var(--white);
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;
  margin-right: 15px;
  text-align: left;
}

.select > div > div {
  border: none !important;
  border-radius: 0.25em !important;
}

select,
.select:after {
  grid-area: select;
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid var(--gray--tab);
  border-radius: inherit;
}

.select_btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  border: 0 solid var(--gray--tab);
  border-left-width: 1px;
  background-color: var(--gray-200);
  border-radius: 0 0.25em 0.25em 0;

  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select_icon {
  width: 40%;
  height: 30%;
  background-color: var(--primary-black);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  pointer-events: none;
}

.option_placeholder {
  display: none;
}

.option {
  color: var(--primary-black);
}
