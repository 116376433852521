.select_price_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.select_price {
  width: 55%;
}

.editable_btn_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer_table {
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
}

.add_pallets_btn {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: #C64641;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  border: none;
  outline: none;
}

.deleteBtn {
  margin-left: 10px;
  color: var(--white);
  background: var(--primary-blue);
  border: none;
}

.deleteBtn:hover,
.deleteBtn:focus {
  background: var(--grey);
}

.editable_cell_form_item, .editable_cell_form_item_adr {
  margin: 0;
  text-align: start;
}

.td_input {
  vertical-align: top;
}

.update_btn {
  padding: 15px;
}

.editable_cell_form_item_adr {
  min-width: 300px;
  max-width: 300px;
  margin: 0 auto;
}

.merce_adr_option {
  border-bottom: 1px solid #c5c5c5;
}

.merce_adr_select_row {
  font-size: 14px;
  text-align: start;
}

.merce_adr_title {
  color: #c5c5c5;
  white-space: nowrap;
}
