.shipmentTwo {
  width: 75vw;
  height: 540px;
  padding: 45px 85px 0 65px;
}

.checkboxes {
  margin: 5px 25px 20px 0;
}

.contacts {
  display: flex;
  justify-content: space-between;
}

.contactEmail,
.contactPhone {
  width: 370px;
  margin-right: 100px;
}

.shipmentBtn {
  width: 250px;
  height: 55px;
  justify-content: center;
  margin-bottom: 15px;
}

.extra_title {
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.extra_title > img {
  padding-left: 5px;
}

.radio_group {
  margin-top: 25px;
}

.list_elements {
  width: 150px;
  margin-right: 5px;
}

.flex {
  display: flex;
  height: 30px;
}

.facchinaggio_width {
  width: 200px
}