.container {
    margin-bottom: 50px;
    background-color: #fff;
}

.note_header {
    width: 100%;
    background: var(--grey-form-backround);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.note_title {
    color: var(--primary-blue);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    padding: 15px 0;
}

.content_container {
    padding: 20px 0;
}

.note_delivery_type {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #1B2936;
    padding: 5px 0;
}

.options_padding {
    display: flex;
    align-items: flex-end;
}

.iconBtn,
.iconBtn:hover,
.iconBtn:focus {
    color: var(--white);
}

.deleteBtn {
    margin-left: 10px;
    color: var(--white);
    background: var(--primary-blue);
    border: none;
}

.deleteBtn:hover,
.deleteBtn:focus {
    background: var(--grey);
}

.btn_container {
    align-self: center;
}