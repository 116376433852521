.asociaModal {
  top: 0;
  font-weight: 600;
  border-radius: 30px;
}
.modalFooter {
  display: flex;
  margin: 0 0 40px 40px;
}

.confirm {
 color: var(--white);
}
  
.modalTitle {
  margin-top: 30px;
  padding: 0 24px;
  text-transform: uppercase;
  font-weight: 800;
}
  
.btn {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0 25px;
}

.btnTitle {
  padding: 0 30px;
}

.table {
  max-width: 100%;
}

.row td,
.table th {
  padding-left: 16px !important;
}

.table ul {
  margin-right: 8px !important;
}
