.loader_container {
    border-top: 1px dashed rgb(27, 41, 54, 0.2);
    width: 100%;
    padding: 40px 10%;
}

.top_loader {
    margin-top: 100px;
}

.uploadBtn {
    margin-right: 20px;
    padding: 15px 50px;
}

.shipping_information_container {
    border-top: 1px dashed rgb(27, 41, 54, 0.2);
    width: 100%;
    background: var(--grey-form-backround);
}

.cost_container {
    margin-left: 10%;
    margin-top: 35px;
}

.cost_text {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: var(--black);
    text-align: left;
    margin-bottom: 20px !important;
}

.price_text {
    margin-left: 10px;
    font-weight: 800;
    font-size: 30px;
    line-height: 35px;
    color: var(--primary-red)
}

.options_container {
    display: flex;
}

.options_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--black);
}

.options_bolt_text {
    font-weight: 900;
    margin-right: 40px;
}

.addPalletsBtn {
    padding-left: 10%;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
}

.shipping_information_button_container {
    display: flex;
    padding-left: 10%;
    margin-top: 65px;
    padding-bottom: 15px;
}

.confirmBtn {
    padding: 15px 40px;
    margin-right: 15px;
}

.cancelBtn {
    padding: 15px 40px;
}

.pallet_form {
    padding-left: 10%;
}
