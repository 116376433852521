.help_container {
    width: 70%;
    margin-top: 90px;
    padding: 85px 0 90px 30%;
}

.help_form {
    margin-top: 25px;
}

.help_form_input {
    width: 400px;
}
.help_form_textarea {
    width: 400px;
    min-height: 160px !important;
}
.helpBtn {
    padding: 20px 100px;
}

.faq_container {
    position: absolute;
    left: -125px;
    width: 400px;
    height: 380px;
    border-radius: 10px;
    background: var(--bg-blue);
    padding: 90px 55px 55px;
}

.faq_text {
    color: var(--white);
    font-weight: 800;
    font-size: 25px;
    line-height: 29px;
}

.faqBtn, a.faqBtn {
    margin-top: 30px;
    padding: 20px 50px!important;
    width: 100%;
    line-height: 0;
    border-radius: 30px;
    font-weight: 600;
}
