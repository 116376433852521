.custom_modal {
  position: relative;
  z-index: 1000;
  top: 0;
}

.custom_modal .ant-modal-content {
  background: var(--grey-form-backround);
  display: flex;
  flex-direction: column;
}

.custom_modal .ant-modal-close {
  position: relative;
  align-self: flex-end;
} 
.custom_modal .ant-modal-body {
  padding: 0;
  padding-bottom: 50px;
}

.carousel_button_right {
  top: 50%;
  right: 3%;
  position: fixed;
  transform: translateY(-50%);

  width: 67px;
  height: 67px;
  background: var(--white);
  border: none;
  color: var(--primary-blue);
}

.carousel_button_left {
  top: 50%;
  left: 3%;
  position: fixed;
  transform: translateY(-50%);

  width: 67px;
  height: 67px;
  background: var(--white);
  border: none;
  color: var(--primary-blue);
}
