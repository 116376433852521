.cell_styles {
  padding: 3px!important;
}

.id_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.annullata {
  color: var(--primary-red);
  font-weight: bold;
  font-size: 12px;
}
