.manifest_header {
  display: flex;
  justify-content: space-between;
  padding: 35px 60px;
}

.title {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
}

.date_picker {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 0 14px;
  height: 32px;
}

.select_container {
  display: flex;
  flex-direction: column;
  width: 155px;
}

.dnd_container {
  margin: 25px 0 15vh;
  padding: 0 60px 85px;
  max-width: 100vw;
  overflow-x: auto;
  align-items: flex-start;
}

.dnd_container::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.dnd_container::-webkit-scrollbar-track {
  background-color: #C4C4C4;
  border-radius: 100px;
}

.dnd_container::-webkit-scrollbar-thumb {
  background-color: #1B2936;
  border-radius: 100px;
}