@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

.estimations_container {
  position: absolute;
  width: 100%;
  background: var(--grey-form-backround);
  z-index: 1;
}

.height_container {
  margin: 35px 0 35px 60px;
}

.newQuoteBtn {
  width: 171px;
  height: 38px;
  justify-content: center;
}

.searchBtn {
  color: var(--gray--tab);
  width: 156px;
  height: 34px;
  border: 1px solid var(--grey);
  margin-right: 8px;
  padding: 9px;
}

.resetBtn {
  color: var(--gray--tab);
  width: 83px;
  height: 34px;
  border: 1px solid var(--grey);
  padding: 9px;
}

.searchBtn:hover,
.searchBtn:active,
.searchBtn:focus,
.resetBtn:hover,
.resetBtn:active,
.resetBtn:focus {
  color: var(--gray--tab);
}

.shipping_status_id {
  text-transform: lowercase;
}

.filter_container {
  display: flex;
  margin-top: 10px;
}

.filter_container > span {
  margin-left: 5px;
}

.primary_color {
  color: var(--primary-red);
}

.email_column {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.detail_checkbox_title {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
}
.modal_background {
  padding: 24px;
}
.detail_modal_container {
  height: calc(100vh - 20px);
  overflow-y: auto;
  overflow-x: hidden;
}
.detail_top_container {
  padding-top: 26px;
}

.address_column {
  display: flex;
  align-items: baseline;
  text-align: start;
}

.address_styles {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.on_edit_container {
  display: flex;
  align-items: flex-end;
}

.on_edit_btn {
  height: 20px;
}

.detail_right_container {
  margin-left: 100px;
}

.cost_text {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: var(--black);
}

.price_text {
  margin-left: 10px;
  font-weight: 800;
  font-size: 30px;
  line-height: 35px;
  color: var(--primary-red);
}

.gap_container {
  gap: 15px;
}

.detail_button {
  padding: 15px 10px;
}

.options_container {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  gap: 10px;
}

.options_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  margin-right: 12px;
}

.options_bolt_text {
  font-weight: 900;
  margin-right: 5px;
  margin-left: 5px;
}

.footer_modal {
  height: 50px;
  background: var(--white);
}

.addPalletsBtn {
  padding-left: 5%;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
}

.pallet_form {
  padding-left: 5%;
}

.assign_price_list_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}

.assign_price_list_input {
  width: 195px;
  margin-right: 10px;
}

.assign_price_list_button {
  padding: 15px 20px;
}

.date_margin {
  margin-top: 13px;
  margin-bottom: 5px;
}
.modal_button {
  margin-top: 20px;
  padding: 15px 30px;
}

.button_style {
  padding: 15px;
}

.estimation_approve_btn {
  padding: 15px 40px;
}

.user_info {
  margin-bottom: 10px;
  text-align: left;
}

.select_input {
  width: 135px;
}

.input_modal,
.select_input_modal {
  width: 100%;
}

.price_list_form {
  margin-top: 30px;
  margin-left: 25%;
}

.price_list_container {
  margin-top: 10px;
}

.contractorSelector,
.contractorSelector > div {
  width: 100%;
}

.contractorSelector > div div:last-child {
  width: calc(100% - 14px);
  text-align: right;
}

.contractorSelector {
  padding-right: 8px !important;
}

.contractorSelectorArrow {
  justify-content: end;
}

.labelSearch {
  text-align: left;
}
.input_number_width {
  width: 400px;
}

.priceForm {
  justify-content: center;
}

.normal_input {
  width: 400px;
}

.narrow_input {
  width: 60px;
  height: 40px;
}

.mb1 {
  margin-bottom: 1rem;
}

.status_select {
  width: 400px;
}

.contractors_container_title {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.left_text {
  text-align: left;
  padding: 0;
  display: flex;
  align-items: center;
}

.left_text p {
  margin-right: 10px;
}

.status_top {
  margin-top: 15px;
}

.disabled_button {
  opacity: 0.4;
}


.status_container {
  display: block;
  position: relative;
  line-height: normal;
  margin-left: 20px !important;
}

.inline {
  display: flex;
}

.fasci_text_styles {
  padding: 0; 
  margin: 0;
  font-size: 14px;
  text-align: start;
}

.modal_label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.gdo_select_name,
.gdo_select_address {
  margin: 0;
  padding: 0;
  text-align: start;
}

.gdo_select_name {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.gdo_select_address {
  font-size: 12px;
  color: #c5c5c5;
}

.error_message {
  font-size: 14px;
  color: #ff4d4f;
  line-height: 16px;
  margin-top: 5px;
  font-weight: 400;
  text-align: start;
}

.update_btn {
  padding: 15px;
}

.col_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.gdo_label {
  background-color: var(--primary-red);
  color: #fff;
  padding: 1px 3px;
  border-radius: 5px;
}
