.tracking_modal_container {
  top: 0;
  height: auto;
  padding: 0;
}

.tracking_modal_container .ant-modal-content {
  height: 100%;
  padding: 20px 40px 40px 40px;
  overflow: auto;
}

.modal_trackint_title {
  font-weight: 800;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #1B2936;
}

.not_tracking_text {
  margin-top: 50px;
  color: #1B2936;
  font-weight: 600;
  font-size: 16px;
}