.priceListTable tr[data-row-key$="-0"] td:first-child {
  border-right: 1px solid rgba(27, 41, 54, 0.3);
}

.priceListTable tr:last-child td {
  border-bottom: 1px solid rgba(27, 41, 54, 0.3);
}

.select {
  height: 30px;
}

.modal {
  width: 300px;
  box-shadow: 0 7px 20px 6px rgba(0,0,0,0.51);
  padding: 0;
}

.modalBtn {
  float: right;
  margin: 0 30px 80px 0;
}

.bold {
  text-transform: capitalize;
  font-weight: 700;
}

p {
  font-weight: 500;
}

.inline {
  display: flex;
}

.select_price {
  margin-left: 10px;
}

.top {
  margin-bottom: 20px;
}

.gap_cursor {
  margin-left: 10px;
  cursor: pointer;
}

.counter form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.percentSelect {
  height: 36px;
  max-width: 100px;
  border: 1px solid var(--primary-red);
  border-right: none !important;
}
.percentSelect > div {
  border: none !important;
  height: 24px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 24px !important;
}

.percentInput {
  max-width: 100px;
  border: 1px solid var(--primary-red);
}

.percentInput input {
  height: 34px;
  padding: 0 11px;
  border: none !important;
  border-right: 1px solid var(--primary-red) !important;
}

.percentInput .percentInput,
.percentInput input + span {
  border: none !important;
}

.percentInput input:focus {
  box-shadow: none !important;
}

.formItem {
  margin-bottom: 0;
}

.formItemInput {
  width: 80px;
}

.btnOk {
  color: var(--white);
  border-radius: 0 10px 10px 0;
  height: 36px;
  padding: 0 5px;
}

.editableCell {
  width: max-content;
  display: inline-block;
  padding: 0 8px;
}

.editableCell:hover {
  color: var(--primary-red);
  background: rgba(198, 70, 65, 0.2);
  border-radius: 5px;
}

.editableCellPencil {
  display: none;
  margin-left: 6px;
  height: 10px;
}

.editableCell:hover .editableCellPencil {
  display: inline-block;
}

.redPercent {
  color: var(--primary-red);
}

.greenPercent {
  color: #438F3C;
}

.extra_name {
  display: flex;
  flex-direction: column;
}

.not_available {
  color: var(--primary-red);
  font-size: 12px;
  font-weight: bold;
}
