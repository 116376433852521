.round {
  position: relative;
  height: 25px;
  display: flex;
  align-items: center;
}

.radio_input {
  visibility: hidden;
}

.label {
  padding-left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.label::before {
  content: '';
  position: absolute;
  border: 1px solid var(--gray-radio-button);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  left: 0;
}

.label::after {
  border: 2px solid var(--white);
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  opacity: 0;
  position: absolute;
  top: 8px;
  left: 6px;
  transform: rotate(-45deg);
  width: 14px;
}

.radio_input:checked + .label::before {
  background-color: var(--primary-red);
  border-color: var(--primary-red);
}

.radio_input:checked + .label::after {
  opacity: 1;
}
