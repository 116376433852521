.pricelist_container {
  position: absolute;
  width: 100%;
  background: var(--grey-form-backround);
}

.height_container {
  margin: 35px 0 35px 60px;
}

.filter_container {
  display: flex;
}

.search {
  width: 400px;
  margin-right: 25px;
}

.searchBtn {
  color: var(--gray--tab);
  width: 156px;
  height: 34px;
  border: 1px solid var(--grey);
  margin-right: 8px;
  padding: 9px;
}

.resetBtn {
  color: var(--gray--tab);
  width: 83px;
  height: 34px;
  border: 1px solid var(--grey);
  padding: 9px;
}

.btn:focus, .btn:hover {
  color: var(--gray--tab);
}

.newQuoteBtn {
  width: 171px;
  height: 38px;
  justify-content: center;
}

.options {
  position: absolute;
  z-index: 200;
  top: 50px;
  right: 60px;
  width: 200px;
  height: 210px;
  padding: 15px;
  box-shadow: 1px 12px 23px 3px rgba(0, 0, 0, 0.3);
}

.optionsBtn {
  width: 45px;
  font-weight: 900;
  border-radius: 30px;
  border: none;
}

.optionsBtn:hover {
  color: var(--primary-red);
  background-color: var(--grey-form-backround);
}

.semiBold {
  font-weight: 700;
}

.bold {
  font-weight: 900;
}

.red {
  font-weight: 700;
  color: var(--primary-red);
}

.advancedSearch {
  display: flex;
  margin: auto;
  justify-content: start;
  width: 95vw;
  gap: 15px !important;
}

.advancedSearch > div {
  margin-left: 20px;
}

.select {
  margin: 0;
  text-align: start;
}


.filter_select {
  width: 200px;
}