.scarico_details_select {
  width: 210px!important;
}

.scarico_details_select .ant-select-selector {
  border: 1px solid #AAAAAA!important;
  border-radius: 5px!important;
  height: 45px!important;
}


.scarico_details_select .ant-select-selection-item {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0!important;
}

.scarico_form .ant-form-item {
  margin-bottom: 0!important;
}

.scarico_details_modal_container .ant-modal-body {
  padding: 37px;
}