.users_container {
    position: absolute;
    width: 100%;
    background: var(--grey-form-backround);
}

.margin_container {
    margin: 35px 0 35px 60px;
}

.newUserBtn {
    width: 171px;
    height: 38px;
    justify-content: center;
}

.filter_container {
    display: flex;
}

.searchBtn {
    color: var(--gray--tab);
    width: 156px;
    height: 34px;
    border: 1px solid var(--grey);
    margin-right: 8px;
    padding: 9px;
}

.resetBtn {
    color: var(--gray--tab);
    width: 83px;
    height: 34px;
    border: 1px solid var(--grey);
    padding: 9px;
}

.searchBtn:hover,
.searchBtn:active,
.searchBtn:focus,
.resetBtn:hover,
.resetBtn:active,
.resetBtn:focus {
    color: var(--gray--tab);
}

.filter_input {
    width: 400px;
    margin-right: 20px;
}

.primary_color {
    color: var(--primary-red);
}

.bold {
    font-weight: bold;
}

.user_modal {
    padding: 0 65px 20px 65px;
}

.form_modal {
    margin-top: 40px;
}
.number_input {
    width: 70px;
}

.add_user_button {
    padding: 20px 45px;
}

.user_profile_container {
    padding: 30px 40px 65px 40px;
}

.space_top {
    margin-top: 15px;
}

.user_profile_button {
    padding: 20px 30px;
}

.circle_button {
    width: 80px;
    height: 45px;
    border-radius: 100px;
    display: block;
}

.right {
    position: absolute;
    right: 40px;
}

.tag_price_list {
    margin-top: 10px;
    width: auto;
    min-width: 125px;
    height: 23px;
    text-align: center;
    border-radius: 100px;
    background-color: var(--blue-status-bgc);
    color: var(--blue-status-c);
    border: none;
}

.user_profile_gap {
margin-top: 30px;
}

.user_profile_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.input_number {
    width: 250px !important;
}

.labelSearch {
    text-align: left;
}

.vat_number_title {
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
}

.vat_number_description {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
}

.primary_blue {
    background: var(--primary-blue);
    color: var(--white);
}

.primary_blue:hover {
    background-color: var(--grey);
    color: var(--white);
    border: none;
}
.primary_blue:active, .primary_blue:focus {
    background-color: var(--primary-blue);
    color: var(--white);
    border: none;
}
