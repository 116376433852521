.form_container {
  padding: 50px 10%;
  width: 100%;;
}

.title {
  padding: 25px 0 5px 0;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 25px;
}

.subtitle {
  padding-top: 30px;
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 20px;
  text-align: start;
}

.buttons {
  margin: 20px 0 150px 0;
}

.proceed {
  display: flex;
  justify-content: center;
  margin-right: 15px;
  width: 185px;
}

.cancel {
  display: flex;
  justify-content: center;
  width: 140px;
}

.input_container {
  display: flex;
  align-items: flex-end;
}

.note_style {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
}

.options_gap_container {
  gap: 48px;
  align-items: flex-end;
}
