.details_container {
  position: absolute;
  width: 100%;
}

.footer_modal {
  background-color: #fff;
  padding: 30px 0;
}

.pallet_form {
  padding-left: 3%;
}

.add_pallets_btn {
  padding-left: 5%;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
}
