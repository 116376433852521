.tabs_container {
  display: flex;
  margin-bottom: 30px;
}

.tab {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  cursor: pointer;
}

.tab_number {
  font-size: 18px;
  line-height: 21px;
  padding-left: 5px;
}

.tab_disabled {
  color: var(--gray--tab);
}

.vl {
  border-left: 4px solid var(--primary-blue);
  height: 22px;
  margin: 0 15px;
}
