.drawer_is_open_mask {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.45);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.routes_board {
  position: fixed;
  top: -40px;
  right: 0;
  z-index: 111;
  max-height: 234px;
  width: 350px;
  padding: 15px;
  background-color: #FFFFFF;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: none;
  flex-direction: column;
  grid-gap: 5px;
}

.routes_board_conteiner {
  padding: 15px;
  overflow: auto;
}

.routes_board_conteiner::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.routes_board_conteiner::-webkit-scrollbar-track {
  background-color: #C4C4C4;
}

.routes_board_conteiner::-webkit-scrollbar-thumb {
  background-color: #212121;
}

.checkbox_item {
  display: flex;
  align-items: flex-start;
}

.item_content {
  display: flex;
}

.round_color {
  display: block;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  margin-right: 15px;
}

.board_info {
  display: flex;
  flex-direction: column;
  color: var(--primary-blue);
  line-height: 16px;
}

.truck_number {
  font-size: 20px;
  line-height: 23px;
  font-weight: 900;
}

.drawer_map_container {
  background-color: #eee;
  position: fixed;
  height: 90vh;
  width: 100%;
  bottom: 0;
  transition: .3s;
  transform: translateY(91%);
  z-index: 11;
}

.btn_container {
  height: 20px;
  background-color: var(--primary-blue);
  position: relative;
}

.drawer_btn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -99%);
  width: 300px;
  height: 50px;
  padding: 0;
  border-radius: 100px 100px 0px 0px;
  background-color: var(--primary-blue);
  color: #FFFFFF;
  border: none;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.map_container {
  height: 100%;
  width: 100%;
}

.drawer_is_open {
  transform: translateY(0);
}

.drawer_is_open .btn_container {
  background-color: var(--primary-red);
}

.drawer_is_open .drawer_btn {
  background-color: var(--primary-red);
}

.drawer_is_open .routes_board {
  display: flex;
}

.blur {
  filter: blur(3px);
}


.markers {
  height: 23px;
  width: 23px;
  background-color: red;
  border-radius: 50%;
  position: relative;
}

.markers svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}